<template>
  <div>
    <!--  备案许可证-->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="生产许可证"></FromLabel>
        <div class="allWidth">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.gif.png等格式的图片">
            <span slot="append" class="finger">
              <FormUpload label="上传"></FormUpload>
            </span>
          </Input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="有效期至" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165"></DatePicker>
          <span class="afterWords14">至</span>
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth165"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="许可证编号" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="法人" showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="生产地址" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="生产范围"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="发证部门"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="发证日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
export default {
  name: 'productionLicensF',
  components: {
    FromLabel,
    FormUpload
  },
  data() {
    return {
      type: '',
      license: [],
      isLoad: false
    }
  },
  created() {},
  methods: {
    changePage(e) {
      console.log(e)
    },
    addBill() {
      console.log('新增开票信息')
    },
    deleteBill() {}
  }
}
</script>

<style scoped lang="less">
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
